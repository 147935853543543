.slideshow {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.slideshow:after {
  display: block;
  content: "";
  padding-bottom: 27%;
}
.slideshow__slide {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  width: 100%;
}
.slideshow .slideshow__slide {
  opacity: 1;
  transition-property: opacity;
}
.slideshow .slideshow__slide.slideshow--slidein {
  opacity: 0;
}
.slideshow .slideshow__slide.slideshow--slideout {
  opacity: 0;
}
.slideshow .slideshow__slide img {
  max-width: 100%;
  height: auto;
}